<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">
      Adres zonder geocoding
    </h1>

    <div class="w-full max-w-3xl mx-auto">
      <UIInput v-model="internalApiKey" label="Internal API Key" />
      <UIInput v-if="GoogleMapsKey" disabled v-model="GoogleMapsKey" />
      <UIInput v-else textarea rows="3" v-model="textareaGoogleKeys" label="Google Maps Key" />

      <div class="flex flex-row items-center gap-2 mt-4">
        <button v-if="!GoogleMapsKey" type="submit" :disabled="!textareaGoogleKeys" class="btn success py-2" @click="() => setGoogleMapsKey()">Gebruik API Key</button>
        <button v-else type="button" class="btn error py-2" @click="() => removeGoogleMapsKey()">Remove</button>
        <small>{{ countCalls }} / 100</small>
      </div>
    </div>

    <div v-if="GoogleMapsKey" class="hidden lg:block mt-8">
      <UILoading v-show="loading"></UILoading>
      <p v-if="!item" v-show="!loading" class="no-data block text-center">Geen adressen meer gevonden</p>
      <div v-else v-show="!loading" class="flex flex-col items-center">

        <ul v-if="item?.id" class="card flex flex-col gap-2 w-full max-w-2xl">
          <li v-for="(_, key) in item" :key="`${key}${item.id}`" class="mb-1 flex flex-row gap-2 items-center">
            <UIInput :disabled="ignoreKeys.includes(key)" v-model="item[key]" :label="key.replace(/_/, ' ').toUpperCase()" :placeholder="''" />
          </li>
        </ul>

        <div class="w-1/2 mx-auto">
          <div class="flex flex-col lg:flex-row gap-8 justify-center">
            <button type="button" :disabled="!isDisabled" class="w-24 lg:w-32 xl:w-48 btn warning mt-4 py-2" @click.prevent.stop="() => handleGeocode()">Geocode</button>
            <button type="button" :disabled="isDisabled" class="w-24 lg:w-32 xl:w-48 btn success mt-4 py-2" @click.prevent.stop="() => handleOpslaan()">Opslaan</button>
          </div>

          <div v-if="item?.id" class="bg-red-100 border-red-300 text-red-900 text-lg border rounded-lg px-8 py-4 mt-4">
            <strong>Opgelet!</strong> Deze aanpassingen zijn onomkeerbaar! Wees zeker!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import clone from 'just-clone'
import axios from 'axios'
import { computed, ref, watch } from 'vue'

import usePaginatedApi from '@/hooks/usePaginatedApi'
// import useModal from '@/hooks/useModal'
import useApi from '@/hooks/useApi'

import UILoading from '@/components/UI/Loading.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import shuffleArray from '@/functions/shuffleArray'

const api = useApi()
// const [openFixedModal] = useModal({ fixed: true })

const removeKeys = [
  'adres',
  'creation_date',
  'update_date',
]

const ignoreKeys = [
  'id',
  ...removeKeys,
]


const internalApiKey = ref('')
const GoogleMapsKey = ref('')
const textareaGoogleKeys = ref('')

const apiData = computed(() => ({ key: internalApiKey.value }))
const { /* offset, */ items, getData, loading } = usePaginatedApi('GEOCODE_ADRES_NEXT', apiData, {
  skipInitial: true,
  defaultLimit: 1,
})

watch(GoogleMapsKey, (val) => {
  if (val) getData()
})

const item = computed({
  get() {
    return items.value?.length ? items.value[0] : null
  },
  set(value) {
    if (!items.value?.length) return
    items.value[0] = value
  },
})

const isDisabled = computed(() => {
  const { id, postal_code, locality, country, lng, lat } = item.value || {}
  return !id || !postal_code || !locality || !country || !lng || !lat
})

const countCalls = ref(0)

const removeGoogleMapsKey = () => {
  GoogleMapsKey.value = ''
  countCalls.value = 0
}

const setGoogleMapsKey = async () => {
  const keys = textareaGoogleKeys.value.split('\n').map(key => String(key || '').trim()).filter((key) => key)
  shuffleArray(keys)

  let result = false

  for (const key of keys) {
    result = await geocodeAdres('Brussel', key)
    console.log({ key, success: result })
    if (result) {
      countCalls.value = 0
      GoogleMapsKey.value = key
      break
    }
  }

  if (!result) {
    GoogleMapsKey.value = ''
    alert('All keys failed')
  }
}

const geocodeAdres = async (adres = '', key = '') => {
  if (!adres) return null
  const apiKey = key || GoogleMapsKey.value
  countCalls.value += 1
  return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?language=nl&region=BE&address=${adres}&key=${apiKey}`)
    .then(({ data }) => {
      if (data.status === 'REQUEST_DENIED') return null
      console.log({ adres, data })
      return data
    })
    .catch((err) => {
      console.error(err)
      return null
    })
}

const getPlaceParts = (place) => {
  return (place?.address_components || []).reduce((result, item) => {
    const value = item.short_name || item.long_name
    if (!value || !item?.types?.length) {
      return result
    }
    item.types.forEach((type) => {
      result[type] = value
    })
    return result
  }, {})
}

const handleGeocode = async () => {
  const adres = String(item.value.adres || '').trim()
  if (!adres) return null

  const data = await geocodeAdres(adres)
  if (!data.results?.length) return null

  const place = data.results[0]
  const parts = getPlaceParts(place)

  let placeLocality = {}
  let partsLocality = {}

  if (parts && !parts.postal_code && parts.locality) {
    const dataLocality = await geocodeAdres(parts.locality)
    if (dataLocality.results?.length) {
      placeLocality = dataLocality.results[0]
      partsLocality = getPlaceParts(placeLocality)
    }
  }

  const oldItem = {
    ...(item.value || {}),
  }

  const newItem = {
    ...(oldItem),
    postal_code: parts?.postal_code || partsLocality?.postal_code || oldItem.postal_code,
    locality: parts?.locality || partsLocality?.locality || oldItem.locality,
    country: parts?.country || partsLocality?.country || oldItem.country,
    lng: place?.geometry?.location?.lng || placeLocality?.geometry?.location?.lng || oldItem.lng,
    lat: place?.geometry?.location?.lat || placeLocality?.geometry?.location?.lat || oldItem.lat,
  }

  item.value = newItem

  if (countCalls.value >= 100) {
    await setGoogleMapsKey()
  }

  let continueAutomatic = true
  // if (oldItem.postal_code && oldItem.postal_code !== newItem.postal_code && !adres.includes(newItem.postal_code)) continueAutomatic = false
  // if (oldItem.locality && oldItem.locality !== newItem.locality && !adres.includes(newItem.locality)) continueAutomatic = false
  // if (oldItem.country && oldItem.country !== newItem.country) continueAutomatic = false
  if (!newItem.postal_code || !newItem.locality || !newItem.country || !newItem.lng || !newItem.lat) {
    continueAutomatic = false
  }

  if (isDisabled.value) continueAutomatic = false
  if (!GoogleMapsKey.value) continueAutomatic = false

  if (!continueAutomatic) {
    alert('Automatisch gestopt, iets niet ingevuld ?')
    return
  }

  await new Promise((resolve) => setTimeout(resolve, 300))
  await handleOpslaan(continueAutomatic)
}

const handleOpslaan = (continueAutomatic = false) => {
  // const callback = () => {
  const data = clone(item.value)
  removeKeys.forEach((key) => {
    delete data[key]
  })
  api('GEOCODE_ADRES_MERGE', { ...data, key: internalApiKey.value }).then(async (res) => {
    await getData()
    if (continueAutomatic) {
      await handleGeocode()
    }
    return res
  })
  // }
  // openFixedModal('BEVESTIG_ACTIE', { message: 'Weet je zeker deze adres gegevens te willen mergen?', callback })
}
</script>
